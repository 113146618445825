import { Container, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Activate() {
	const navigate = useNavigate();
	return (
		<Container>
			<Stack sx={{ p: 2 }}>
				Kommer snart: Her kan du aktivere sesongkortene dine.
				<Button onClick={() => navigate("/")}>Tilbake</Button>
			</Stack>
		</Container>
	);
}
