import {
	Box,
	Button,
	Container,
	Dialog,
	FormControl,
	InputLabel,
	LinearProgress,
	List,
	ListItem,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, initiatePayment } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import Terms from "./Terms";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { VippsLoginButton } from "./VippsLoginButton";

type Props = {};

const Tickets = (props: Props) => {
	const [storeItems, setStoreItems] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState(true);

	// Read url parameters

	const location = useLocation();
	console.log("location", location.search);
	const showTypes = new URLSearchParams(location.search).get("showTypes");

	console.log("showTypes", showTypes);

	/* const [showTicketTypes, setShowTicketTypes] = React.useState(
		showTypes ? showTypes.split(",") : []
	); */

	useEffect(() => {
		const fetchStoreItems = async () => {
			// Fetch all data from firestore table "store"
			const data = await getDocs(collection(db, "store"));
			const items = data.docs.map(
				(doc) =>
					({
						id: doc.id,
						...doc.data(),
					} as any)
			);

			const filteredItems = items.filter(
				(item) => showTypes === item.type
			);
			filteredItems.sort((a, b) => (a.price > b.price ? 1 : -1));

			setStoreItems(filteredItems);

			setLoading(false);
		};
		fetchStoreItems();
	}, [showTypes]);

	// Remaining code...
	if (loading) return <></>;

	console.log("showTicketTypes", showTypes);

	return (
		<Container>
			{!showTypes ? (
				<ShopFrontPage />
			) : (
				<>
					{showTypes === "dayTicket" && storeItems.length > 0 && (
						<Box>
							<DayPasses storeItems={storeItems} />
						</Box>
					)}
					{showTypes === "seasonTicket" && storeItems.length > 0 && (
						<SeasonPass storeItems={storeItems} />
					)}
				</>
			)}
		</Container>
	);
};

const ShopFrontPage: React.FC = () => {
	const navigate = useNavigate();
	return (
		<Stack gap={4} pt={3}>
			<Button
				variant="contained"
				onClick={() => {
					navigate("/store?showTypes=dayTicket", {
						replace: true,
					});
				}}
				sx={{
					fontSize: "1.2rem",
					fontWeight: "bold",
					padding: "10px 20px",
				}}
			>
				Dagskort
			</Button>
			<Button
				onClick={() => {
					navigate("/store?showTypes=seasonTicket", {
						replace: true,
					});
				}}
				variant="contained"
				sx={{
					fontSize: "1.2rem",
					fontWeight: "bold",
					padding: "10px 20px",
				}}
			>
				Sesongkort
			</Button>
		</Stack>
	);
};

const PaymentAndTerms: React.FC<{
	numTickets: number;
	selectedItemId: string;
}> = ({ numTickets, selectedItemId }) => {
	const { useTestMerchant, profile } = useAuth();
	const [inProgress, setInProgress] = React.useState(false);
	const [error, setError] = React.useState("");
	const [mobileNumber, setMobileNumber] = React.useState(
		useTestMerchant ? "96682809" : profile?.phoneNumber.slice(2)
	);
	const [termsOpen, setTermsOpen] = React.useState(false);

	useEffect(() => {
		if (profile) {
			setMobileNumber(profile.phoneNumber.slice(2));
		}
	}, [profile]);

	const handleClickPay = async () => {
		setInProgress(true);

		try {
			const result: any = await initiatePayment({
				itemId: selectedItemId,
				numTickets: numTickets,
				mobileNumber,
				fallbackPrefix: `${window.location.origin}/processpayment`,
				testMerchant: useTestMerchant,
				useLocalCallback: window.location.host === "localhost:3000",
			});

			const response = result.data;

			if (response.code !== "OK") {
				// handle error
				console.log("Error: ", result);
				setError("Error: " + result.data.message);
				return;
			} else {
				// Redrect to Vipps payment page
				//setRedirectUrl(result.data.redirectUrl);
				window.location.replace(response.redirectUrl);
				// use router and navigate to redirectUrl
			}
		} catch (error) {
		} finally {
			setInProgress(false);
		}
	};
	return (
		<Stack m={2} gap={3} display="flex">
			<Box>
				<FormControl>
					<PhoneNumberInput
						mobileNumber={mobileNumber}
						onChange={(number) => setMobileNumber(number)}
					/>
				</FormControl>
			</Box>
			<Box>
				Ved kjøp av kort i Bak-Olsen godtar jeg{" "}
				<a href="#" onClick={() => setTermsOpen(true)}>
					salgsvilkårene
				</a>{" "}
				for Bak-Olsen skitrekk.
			</Box>

			<Typography>{error}</Typography>
			{inProgress && <LinearProgress />}

			<Box
				sx={{
					m: 1,
					position: "relative",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button disabled={inProgress} onClick={handleClickPay}>
					<img src="/images/paywithvipps.svg" alt="Betal med Vipps" />
				</Button>
			</Box>
			<Dialog open={termsOpen} onClose={() => setTermsOpen(false)}>
				<Terms onClose={() => setTermsOpen(false)} />
			</Dialog>
		</Stack>
	);
};

const SeasonPass: React.FC<{
	storeItems: any[];
}> = ({ storeItems }) => {
	const { isSignedIn } = useAuth();
	const [selectedItemId, setSelectedItemId] = React.useState<string>(
		storeItems[0]?.id
	);

	if (!isSignedIn) {
		return (
			<Stack>
				<Box>
					Du må være innlogget for å kjøpe sesongkort. Logg inn med
					Vipps
				</Box>
				<Box>
					<VippsLoginButton />
				</Box>
			</Stack>
		);
	} else {
		return (
			<Stack pt={2} gap={2}>
				<Typography variant="h5">Sesongkort</Typography>
				<br />
				Vi selger sesongkort etter et familievennlig prinsipp, men med
				obligatoriske vakter av en voksenperson i hustanden.
				<List>
					{storeItems?.map((item) => (
						<ListItem>{item.description}</ListItem>
					))}
				</List>
				<Box>
					<FormControl>
						<InputLabel id="num-tickets">
							Velg sesongkort
						</InputLabel>

						<Box sx={{ p: 2 }}>
							<Select
								id="subscription-type"
								value={selectedItemId}
								label="Antall"
								onChange={(e) =>
									setSelectedItemId(e.target.value)
								}
							>
								{storeItems?.map((item) => (
									<MenuItem value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</Box>
					</FormControl>
				</Box>
				<PaymentAndTerms
					selectedItemId={selectedItemId!}
					numTickets={1}
				/>
			</Stack>
		);
	}
};

const DayPasses: React.FC<{
	storeItems: any[];
}> = ({ storeItems }) => {
	const [numTickets, setNumTickets] = useState(1);
	const itemId = storeItems[0]?.id;

	return (
		<Box pt={2}>
			<FormControl>
				<Stack m={2} gap={3}>
					<Box>
						<Typography variant="h5">Kjøp dagskort</Typography>
					</Box>

					<Stack
						direction="row"
						sx={{ border: 1, color: "grey", borderRadius: 2 }}
					>
						{storeItems?.map((item) => (
							<>
								<Box
									alignItems="center"
									justifyContent={"space-between"}
									display="flex"
									flexGrow={1}
									key={item.id}
								>
									<Stack
										direction="row"
										alignItems="center"
										gap={2}
									>
										<Checkbox
											checked={itemId === item.id}
										/>
										{item.description}
									</Stack>
									<Box pr={2}>{item.price} kr x</Box>
								</Box>
								<TextField
									//type="number"
									select
									//label="Antall"
									//variant="filled"
									value={numTickets}
									defaultValue={1}
									onChange={(e) =>
										setNumTickets(
											Number(e.currentTarget.value)
										)
									}
								>
									{[1, 2, 3, 4, 5, 6].map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</>
						))}
					</Stack>

					<Box>
						Etter betaling er gjennomført vil du få en SMS med en
						ordkode (f.eks MAGISK DRØM) som kan oppgis til vaktene
						når det er billettkontroll i bakken.
					</Box>
				</Stack>
			</FormControl>
			<PaymentAndTerms selectedItemId={itemId!} numTickets={numTickets} />
		</Box>
	);
};

const PhoneNumberInput: React.FC<{
	mobileNumber: string;
	onChange: (number: string, hasError: boolean) => void;
}> = ({ mobileNumber, onChange }) => {
	const [numberError, setPhoneNumberError] = useState(false);
	const [errorText, setPhoneNumberErrorText] = useState("");

	const handlePhoneNumberChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = e.target.value.slice(0, 8);
		onChange(value, numberError);

		// Check if input has any non-numeric characters

		if (value.length >= 8) {
			if (/^\d{8}$/.test(value)) {
				setPhoneNumberError(false);
			} else {
				setPhoneNumberError(true);
				//setPhoneNumberErrorText("Ikke gyldig telefonnummer");
			}
		} else {
			setPhoneNumberError(false);
			//setPhoneNumberErrorText("");
		}
	};

	return (
		<TextField
			id="mobilePhone"
			label="Telefonnummer"
			value={mobileNumber}
			autoComplete="tel"
			focused={true}
			sx={{ width: 120 }}
			onChange={handlePhoneNumberChange}
			error={numberError}
			helperText={errorText}
		/>
	);
};

export default Tickets;
