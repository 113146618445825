import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Stack, Typography } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

export default function UpdateProfile({ completeIt = false }) {
	const [snackOpen, setSnackOpen] = useState(false);
	const canSubmit = true;

	const { profile, updateProfile } = useAuth();
	const navigate = useNavigate();

	function handleChange(event) {
		//setReadyToSubmit(true);
	}

	function handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		const firstName = data.get("firstname").trim();
		const lastName = data.get("lastname").trim();
		const email = data.get("email").trim();
		//const onBehalfOfPhone = data.get("onbehalfofphone").trim();

		updateProfile({
			firstName,
			lastName,
			email,
			//onBehalfOfPhone,
		}).then((result) => {
			setSnackOpen(true);
			console.log(result);
		});
	}

	/*
	function isValidEmail(email) {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	} */

	/*function handleEmailChange(value) {
		if (isValidEmail(value)) {
			console.log("We have a valid email, checking if it is registered");
			isRegisteredUser({ email: value }).then((result) => {
				console.log(result);
				if (result.data.message === "FOUND") {
					setHelperText("OK - Årskort registrert på denne eposten");
					setCanSubmit(true);
				} else {
					setErrorMessage(null);
					setHelperText(
						"Ingen årskort registrert på denne eposten. Hvis du vil registrere vakter på vegne av noen andre, så kan du legge dette til i profilen din etter registrering."
					);
					setCanSubmit(false);
				}
			});
		} else {
			setHelperText("");
			if (value.length > 0) {
				setCanSubmit(false);
			} else {
				setCanSubmit(true);
			}
		}
	} */

	const handleSnackClose = () => setSnackOpen(false);

	const successSnack = (
		<IconButton
			size="small"
			aria-label="close"
			color="inherit"
			onClick={handleSnackClose}
		>
			<CloseIcon fontSize="small" />
		</IconButton>
	);

	console.log("Profile from autcontext: ", profile);

	return (
		<Grid container component="main" sx={{ height: "100vh" }}>
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: "url(scenery.jpg)",
					backgroundRepeat: "no-repeat",
					backgroundColor: (t) =>
						t.palette.mode === "light"
							? t.palette.grey[50]
							: t.palette.grey[900],
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			/>
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				component={Paper}
				elevation={6}
				square
			>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography component="h1" variant="h5">
						{completeIt
							? "Fullfør profil"
							: "Oppdater profil (" +
							  profile.phoneNumber.slice(2) +
							  ")"}
					</Typography>

					<Box
						component="form"
						noValidate
						onSubmit={handleSubmit}
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							onChange={handleChange}
							required
							fullWidth
							id="firstname"
							label="Fornavn"
							name="firstname"
							autoComplete="firstname"
							autoFocus
							defaultValue={profile?.firstName ?? ""}
						/>
						<TextField
							margin="normal"
							onChange={handleChange}
							required
							fullWidth
							id="lastname"
							label="Etternavn"
							name="lastname"
							autoComplete="lastname"
							autoFocus
							defaultValue={profile?.lastName ?? ""}
						/>

						<TextField
							margin="normal"
							onChange={handleChange}
							required
							fullWidth
							id="email"
							label="Email"
							helperText=""
							name="email"
							autoComplete="email"
							autoFocus
							defaultValue={profile?.email ?? ""}
							//onChange={(e) => handleEmailChange(e.target.value)}
						/>
						{/*<TextField
							margin="normal"
							fullWidth
							id="onbehalfofemail"
							label="Skriv inn telefonummer til den du vil registrere på vegne av"
							helperText={helperText}
							name="onbehalfofphone"
							autoFocus
							onChange={(e) => handleEmailChange(e.target.value)}
						/> */}
						<Stack
							spacing={2}
							sx={{ mt: 4 }}
							justifyContent="center"
							direction="row"
						>
							<Button
								disabled={!canSubmit}
								type="submit"
								variant="contained"
							>
								Lagre endringer
							</Button>
							{!completeIt && (
								<Button
									variant="outlined"
									onClick={() => {
										navigate(-1);
									}}
								>
									Avbryt
								</Button>
							)}
						</Stack>
					</Box>
				</Box>
				<Snackbar
					open={snackOpen}
					autoHideDuration={6000}
					onClose={handleSnackClose}
					message="Note archived"
					action={successSnack}
				>
					<Alert
						onClose={handleSnackClose}
						severity="success"
						sx={{ width: "100%" }}
					>
						Profil oppdatert
					</Alert>
				</Snackbar>
				<div></div>
			</Grid>
		</Grid>
	);
}
