import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "./firebase";
import dayjs from "dayjs";
import { useAuth } from "./contexts/AuthContext";

const emptyChecklist = [
	{
		title: "Sjekket at sikkerhetssnoren står i ved toppstasjonen",
		checked: false,
	},
	{
		title: "Fjernet snø om nødvendig, slik at sikkerhetssnoren henger fritt (30-50cm)",
		checked: false,
	},
	{
		title: "Sjekket øvre nødstopp ved å trykke ned den røde soppen og trukket opp igjen",
		checked: false,
	},
	{
		title: "Sjekket øvre nødstopp ved å trykke ned den røde soppen og trukket opp igjen",
		checked: false,
	},
	{
		title: "Sjekket at slepetraseen og på- og avstigningsplassen er forsvarlig preparert",
		checked: false,
	},
	{
		title: "Sjekket nedre nødstopp ved å trykke ned den røde soppen og trukket opp igjen",
		checked: false,
	},
	{
		title: "Ingen ulyd fra drivstasjonen ved oppstart",
		checked: false,
	},
	{
		title: "Kontrollert start og stoppfunksjon på tavle og mobil konsoll",
		checked: false,
	},
	{
		title: "Vakt(er) vet hva som skal gjøres ved personskader (se oppslag)",
		checked: false,
	},
	{
		title: "Sikkerhetsnor er funksjonstestet før oppstart",
		checked: false,
	},
	{
		title: "Alle skilt/polstringer og gjerder er oppsatt i H.H.T. arrangementplan (se oppslag)",
		checked: false,
	},
];

export const useChecklist = () => {
	const { profile } = useAuth();
	const [checklist, setChecklist] = useState<
		{ title: string; checked: boolean }[]
	>([]);
	const [loading, setIsLoading] = useState(true);

	const allChecked = checklist.every((item) => item.checked);
	const today = dayjs();
	const todayString = today.format("YYYY-MM-DD");

	useEffect(() => {
		// save checklist using todays date as ID

		const saveChecklist = async () => {
			const docRef = doc(db, "liftLog", todayString);

			await setDoc(docRef, {
				checklist,
				phoneNumber: profile?.phoneNumber,
				name: profile.firstName,
				allChecked,
			});
			console.log("Saved checklist to db", checklist);
		};
		if (!loading) saveChecklist();
	}, [
		loading,
		checklist,
		allChecked,
		profile?.phoneNumber,
		profile?.firstName,
		todayString,
	]);

	useEffect(() => {
		// create a snapshot listener for the checklist
		const docRef = doc(db, "liftLog", todayString);
		const unsubscribe = onSnapshot(docRef, (doc) => {
			const data = doc.data();
			console.log("Got checklist data:", data);
			if (data && data.checklist?.length > 0) {
				setChecklist(data.checklist);
			} else if (checklist.length === 0) {
				setChecklist(emptyChecklist);
			}
			setIsLoading(false);
		});

		return unsubscribe;
	}, [todayString, checklist.length]);

	return { items: checklist, allChecked, setChecklist, loading };
};
