import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Profile from "../components/Profile";

const PrivateRoutes = () => {
    const {isSignedIn} = useAuth();
    const {profile} = useAuth();

    const profileHasRequiredFields = () => {
        return ((profile && profile.firstName && profile.lastName && profile.email) ? true : false);
    }

    console.log("Has required fields: ", profileHasRequiredFields());
    
    
    if (!isSignedIn) return (<Navigate to="/public" />);

    return (
        profileHasRequiredFields() ? <Outlet /> : <Profile completeIt={true} />
     )
}

export default PrivateRoutes;