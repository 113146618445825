import _ from "lodash";

const defaultDayConfig = {
    "weekday": {
        isOpen: true,
        openingHours: ["17:30", "20:00"],
        slots: [{
            start: "17:30",
            end: "20:00",
            minRosters: 1,
            maxRosters: 2,
        }]
    },
    "weekend": {
        isOpen: true,
        openingHours: ["12:00", "17:00"],
        slots: [{
                start: "12:00",
                end: "14:30",
                minRosters: 2,
                maxRosters: 2
            },
            {
                start: "14:30",
                end: "17:00",
                minRosters: 1,
                maxRosters: 2,
            }
        ]
    },
    "weekend1": {
        isOpen: true,
        openingHours: ["12:00", "17:00"],
        slots: [{
                start: "12:00",
                end: "14:30",
                minRosters: 1,
                maxRosters: 2
            },
            {
                start: "14:30",
                end: "17:00",
                minRosters: 1,
                maxRosters: 2,
            }
        ]
    },
    "weekend1secondHalf": {
        isOpen: true,
        openingHours: ["14:30", "17:00"],
        slots: [
            {
                start: "14:30",
                end: "17:00",
                minRosters: 1,
                maxRosters: 2,
            }
        ]
    }, "weekend1firstHalf": {
        isOpen: true,
        openingHours: ["12:00", "14:30"],
        slots: [
            {
                start: "12:00",
                end: "14:30",
                minRosters: 1,
                maxRosters: 2,
            }
        ]
    }


}


const RosterCalendar = (startDate, numberOfDays) => {

    const days = []

    for (let d = 0; d < numberOfDays; d++) {
        const dateRef = startDate.add(d, 'day');
        days.push(Day(dateRef))
    }

    const getDay = (date) => {
        return days.find(d => {
            const dd = d.date;
            if (dd.isSame(date, 'day')) {
                return true;
            } else {
                return false;
            }
        });
    }

    const insertRoster = (r) => {
        const rDate = new Date(r.date.seconds * 1000);
        const day = getDay(rDate);
        if (!day) {
            console.log("Error: Date ranges have gone awry"); // TODO proper error logging here

        } else {
            day.insertRoster(r);
        }
    }

    const removeRoster = (r) => {
        const rDate = new Date(r.date.seconds * 1000);
        getDay(rDate).removeRoster(r);
        console.log("DAY AFTER removeRoster", getDay(rDate));
    }


    const insertDayOverride = (o) => {
        const date = new Date(o.date.seconds * 1000);
        if (!getDay(date)) {
            console.log("Error: Date ranges have gone awry, cant get day for ", date); // TODO proper error logging here
        } else {
            getDay(date).insertDayOverride(o);
        }
        //console.log(getDay(date).isOpen());
    }

    const removeDayOverride = (o) => {
        const date = new Date(o.date.seconds * 1000);
        getDay(date).removeDayOverride(o);
    }


    const getDays = () => {
        return days;
    }

    return {
        insertRoster,
        removeRoster,
        insertDayOverride,
        removeDayOverride,
        getDays
    }

}

const Day = (date) => {

    let dayData;

    const dateObj = date;

    const insertRoster = (r) => {
        // Find roster
        const slot = dayData.slots.find(i => i.start === r.start && i.end === r.end);
        if (slot) {
            if (!("rosters" in slot)) slot.rosters = []
            // Insert roster to slot
            if (slot.rosters.length < slot.maxRosters) {
                slot.rosters.push(r);
            } else {
                throw new Error("Maximum number of rosters already registered");
            }
        } else {
            throw new Error("No matching slot found in day when adding roster, day:", dayData, "Attempting to insert roster:", r);
        }
    }


    const removeRoster = (r) => {
        const slot = dayData.slots.find(i => i.start === r.start && i.end === r.end);
        if (slot) {
            // Find the right roster in the list to remove
            slot.rosters = slot.rosters.filter(roster => roster.id !== r.id);
        } else {
            throw new Error("No matching slot found in day when removing roster, day:", dayData, "Attempting to insert roster:", r);
        }
    }

    const getSlots = () => {
        return [...dayData.slots]
    }

    const getAssignableSlots = () => {
        return dayData.slots.filter(slot => slot.rosters.length < slot.maxRosters);
    }

    const isSlotFull = (slot) => {
        return slot?.rosters?.length < slot?.maxRosters ? false : true;
    }

    const showAsOpen = (slot) => {
        return slot?.rosters?.length >= slot.minRosters;
    }

    const isSufficientlyBooked = () => {
        if (!isOpen()) {
            return false;
        }
        /* inspect all slots and ensure num rosters > min rosters */
        // console.log("isSufficientlyBooked", dayData.slots.find(slot => slot.rosters.length < slot.minRosters), dateObj);
        return (!dayData.slots.find(slot => slot.rosters.length < slot.minRosters));

    }

    const insertDayOverride = (override) => {
        //console.log("insertDayOverride ", override);

        if ((override && override.template != null)) {
            console.log("We have a template to use: ", defaultDayConfig[override.template]);
            const templateData = _.cloneDeep(defaultDayConfig[override.template]);
            if (!templateData) throw Error("Template not found:", override.template);
            dayData = templateData;
            dayData.id = override.id;
        } else {
            dayData = override /* TODO: insert override data sanity check */
        }

        /* Insert some empty fields TODO: Accept empty fields?*/
        if (!("slots" in dayData)) dayData.slots = [];
        if (!("openingHours" in dayData)) dayData.openingHours = [];

        dayData.slots.forEach(s => {
            if (!("rosters" in s)) s.rosters = []
        })


        console.log("invert override day data result: ", dayData);

    }

    const removeDayOverride = (data) => {
        console.log("removeDayOverride: ", data);
        setDefaultRosters();
    }


    const getOpeningHours = () => {
        console.log("Openinghours:", dayData.openingHours);
        return dayData.openingHours;
    }

    const getAllRosters = () => {
        const allRosters = [];
        dayData.slots.forEach(s => s.rosters.forEach(r => allRosters.push(r)));
        return allRosters
    }

    const isOpen = () => {
        return dayData.isOpen;
    }

    const getClosedReason = () => {
        return dayData.reason;
    }

    const getId = () => {
        return dayData.id;
    }

    const setDefaultRosters = () => {
        const dayOfWeek = dateObj.day();
        //console.log("Setting default rosters for dayOfWeek ", dayOfWeek)
        if (dayOfWeek === 6 || dayOfWeek === 0) {
            dayData = _.cloneDeep(defaultDayConfig["weekend"]);
        } else {
            dayData = _.cloneDeep(defaultDayConfig["weekday"]);
        }

        dayData.slots.forEach(s => s.rosters = [])
    }

    setDefaultRosters();

    return {
        isOpen,
        getClosedReason,
        date,
        getId,
        isSufficientlyBooked,
        getOpeningHours,
        insertRoster,
        insertDayOverride,
        removeRoster,
        removeDayOverride,
        getSlots,
        showAsOpen,
        getAllRosters,
        isSlotFull,
        getAssignableSlots,
    }
}


export default RosterCalendar;