import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
//import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import { NavLink } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { ListItemIcon, ListItemText } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CalendarViewMonth from '@mui/icons-material/CalendarViewMonth';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StyleIcon from '@mui/icons-material/Style';
import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';

const pagesPublic = [["Informasjon", "/"],["Butikk", "/store"]];

const settings = [
	["Logg ut", "/logout"],
	["Profil", "/profile"],
];

export default function AppNavigationBar() {
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [anchorElUser, setAnchorElUser] = useState(null);
	const { isSignedIn, isAdmin, profile, rosterToday } = useAuth();

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const pagesLoggedIn = [
		["Informasjon", "/", <InfoIcon />],
		["Vaktkalender", "/calendar", < CalendarViewMonth  />],
		["Butikk", "/store", <LocalGroceryStoreIcon />]
	];

	let pages = isSignedIn ? [...pagesLoggedIn] : [...pagesPublic];
	if (isAdmin) pages.push(["Admin", "/admin", <AdminPanelSettingsIcon />]);
	if (profile?.mandatoryRosters > 0 && isSignedIn) pages.push(["Mine kort", "/activate", <StyleIcon />]);
	if ((rosterToday && isSignedIn) || isAdmin) pages.push(["Dagens vakt", "/rosterToday", <SnowshoeingIcon />]);

	console.log("Pages", pages);

	return (
		<AppBar position="fixed" >
			<Container maxWidth="lg">
				<Toolbar disableGutters>
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
					>
						Bak-Olsen
					</Typography>

					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "flex", sm: "none" },
						}}
					>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{ display: { xs: "block", md: "none" } }}
						>
							{pages.map(([page, target, icon]) => (
								<MenuItem
									key={page}
									component={Link}
									to={target}
									onClick={handleCloseNavMenu}
								>
									<ListItemIcon>{icon}</ListItemIcon>
									<ListItemText>
										{page}
									</ListItemText>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{
							flexGrow: 1,
							display: { xs: "flex", md: "none" },
						}}
					>
						Bak-Olsen
					</Typography>
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", sm: "flex" },
						}}
					>
						{pages.map(([page, target, icon]) => (
							<MenuItem
								key={page}
								component={Link}
								to={target}
								style={{ paddingLeft: 13 }}
							>
								<ListItemIcon>{icon}</ListItemIcon>
								{page}
							</MenuItem>
						))}
					</Box>


					{isSignedIn && (
						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Instillinger">
								<IconButton
									onClick={handleOpenUserMenu}
									sx={{ p: 0 }}
								>
									<Avatar alt="Bakolsen">
										{profile?.firstName
											?.slice(0, 1)
											.toUpperCase()}
										{profile?.lastName
											?.slice(0, 1)
											.toUpperCase()}
									</Avatar>
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: "45px" }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								{settings.map(([setting, target]) => (
									<MenuItem
										key={setting}
										component={NavLink}
										to={target}
										onClick={handleCloseUserMenu}
									>
										<Typography textAlign="center">
											{setting}
										</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
}
