import React from 'react';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import CardHeader from "@mui/material/CardHeader";
import AddCircle from "@mui/icons-material/AddCircle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {Divider} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import { reasonTranslator } from '../shared/closedReasonTranslator';

import dayjs from 'dayjs';
import { useAuth } from '../contexts/AuthContext';




export default function CalendarDay({day, addRosterHandler, deleteRosterHandler}) {
    const {profile} = useAuth();

    return (
        <Grid item key="gi" xs={12} sm={6} md={4}>
                <Card elevation={3} bgcolor="background.paper" key="ca" sx={{height: "100%", display: "flex", flexDirection: 'column', alignContent: 'stretch'}}>
                    <CardHeader key="ch" sx={{bgcolor:day.isSufficientlyBooked() ? "#99dd99" : day.isOpen() ? "#dddddd" : "#dd9999",p:1}}
                        title={
                                <Box sx={{ textTransform: 'capitalize' }}>
                                    {day.date.locale("no_nb").format("dddd DD.")}
                                </Box>}
                        subheader={
                                <Box sx={{ textTransform: 'capitalize' }}>
                                    {day.date.format("MMMM")}
                                </Box>}
                        
                        />
                    
                    <CardContent sx={{p:2}} >
                        <Grid display="flex" alignItems="center" justifyContent="center" container sx={{my:"0px"}} width="100%" height="100%">
                            {!day.isOpen() && <Typography>
                                {reasonTranslator[day.getClosedReason()? day.getClosedReason():"closed"]}
                                </Typography>
                            }
                            <Stack divider={<Divider  />} gap={1}>
                            {day.isOpen() && day.getSlots().map((s,i) => (
                                    <Box borderColor="primary">
                                        
                                    <Stack direction="row" spacing={1} alignContent='stretch'>
                                        <Box display="flex" alignItems="center"><Typography variant='h6'>{s.start}-{s.end}</Typography></Box>
                                        
                                        
                                        <Box p={0} width="100%" display="flex" alignItems="center" justifyContent="space-around">
                                            <Stack display="flex" width="100%" id="roster-time" alignContent="center">
                                            {s.maxRosters > s.rosters.length && <>
                                            {!day.date.isBefore(dayjs().add(-1,"day")) &&
                                            <Button sx={{ width: '100%', display:"flex", justifyContent:"flex-start"}} startIcon={<AddCircle/>} variant='contained' color="success" onClick={() => addRosterHandler(day.date, s)} >
                                                {s.minRosters-s.rosters.length === 0 && "Ta medvakt"}  
                                                {s.minRosters-s.rosters.length !== 0 && <> Mangler {s.minRosters-s.rosters.length} vakt{s.minRosters-s.rosters.length >1  ? "er" : "" } </>}
                                            </Button>
                                            }
                                            </>
                                            }
                                         

                                            {s.rosters.map((r, i) => (
                                              <Stack display="flex" direction="row" alignItems="center" id="roster-button"> 
                                                   <Typography>{r.assignedToFirstName} {r.assignedToLastName[0]}. {r.assignedToPhoneNumber.slice(2)} </Typography>
                                                   { r.assignedToPhoneNumber === profile.phoneNumber && 
                                                   <IconButton title="Slett vakt" onClick={(e) => deleteRosterHandler(r)} aria-label="delete" color="primary">
                                                    < DeleteIcon />
                                                   </IconButton> }
                                               </Stack>
                                                ))
                                            }
                                         
                                            

                                            </Stack>

                                        </Box>
                                    </Stack>

        
                                    </Box>
                                ))}
                                </Stack>
                        </Grid>
                    </CardContent>                   
                    </Card>
                </Grid>)
}
