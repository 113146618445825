import { Box } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";

export const VippsLoginButton = () => {
	const { goToVippsSignIn, useTestMerchant } = useAuth();

	const handleLoginVipps = () => {
		// read testMerchange from session storage
		if (useTestMerchant) {
			goToVippsSignIn(true);
		} else {
			goToVippsSignIn(false);
		}
	};

	return (
		<Box display="flex" alignItems="center">
			<img
				onClick={handleLoginVipps}
				alt="Logg inn med Vipps"
				src={"images/LoginVippsNO.png"}
				//src="/loginVippsNO.png"
				style={{ cursor: "pointer" }}
			/>
		</Box>
	);
};
