import { Box, Button, Container, Fade, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useRosterCredentials } from "../hooks/useRosterCredentials";
import { useState } from "react";
import { useChecklist } from "../useChecklist";
import { error } from "console";

export const RosterToday: React.FC = () => {
	const { rosterToday, isAdmin } = useAuth();
	const [showCode, setShowCode] = useState(false);
	const rosterCredentials = useRosterCredentials({ fetchDoorCode: showCode });
	const checklist = useChecklist();

	if (rosterToday === undefined) return <></>;

	if (!rosterToday && !isAdmin)
		return (
			<Container>
				<Box p={2}>
					<Typography>
						Ingen tilgang til deg i dag. Du har ikke vakt påstår
						systemet. Kontakt 48011114 hvis du mener dette er feil.
					</Typography>
				</Box>
			</Container>
		);

	return (
		<Fade in={!checklist.loading} timeout={600}>
			<Container>
				<Stack gap={3} pt={2}>
					<Typography variant="h5">Dagens vakt</Typography>
					<Box>
						Utfør kontroll av heis/bakke ved å gå gjennom
						kontrollskjema.{" "}
					</Box>
					<Button
						to="/rosterToday/liftLog"
						component={Link}
						variant="contained"
						color={checklist.allChecked ? "success" : "primary"}
					>
						Kontrollskjema{" "}
						{checklist.allChecked ? "(fullført)" : ""}
					</Button>
					<Box display="flex" alignItems="center" gap={1}>
						Kode til dørlås i vaktbua er
						{!showCode ? (
							<Button
								variant="outlined"
								onClick={() => setShowCode(true)}
							>
								Vis
							</Button>
						) : rosterCredentials.error ? (
							<Typography variant="body2" color="error">
								Kunne ikke hente kode. Kontakt Vegar på tlf
								48011114: Error: {rosterCredentials.error}
							</Typography>
						) : (
							<Box>
								<Typography variant="h5">
									{rosterCredentials.loading
										? "Henter..."
										: rosterCredentials.doorCode}
								</Typography>
							</Box>
						)}
					</Box>
					<Box>
						Utfør billettkontroll av dagskort ved å spørre om
						kodeord og sjekke at det stemmer med solgte dagskort ved
						å gå inn på dagskortoversikt
					</Box>{" "}
					<Button
						to="/rosterToday/control"
						component={Link}
						variant="contained"
					>
						Dagskortoversikt
					</Button>
					<Button variant="contained">
						Avslutt vakt (siste vakt for dagen)
					</Button>
				</Stack>
			</Container>
		</Fade>
	);
};
