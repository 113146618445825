import { useEffect, useState } from "react";
import { getDoorCode } from "../firebase";

export const useRosterCredentials:(opts: {fetchDoorCode:boolean}) =>{doorCode:string|undefined, error:boolean|undefined, loading:boolean, errorMessage:string}  = ({fetchDoorCode}) => {
    const [doorCode, setDoorCode] = useState<string>();
    const [error, setError] = useState<boolean>();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    

    const getDC = async () => {
        setError(false);
        setErrorMessage("");
        try {
            setLoading(true);
            const res = await getDoorCode();
            setDoorCode((res.data as { doorCode: string }).doorCode);
        } catch (e: any) {
            console.error("Error fetching door code:", e);
            setError(true);
            if (e.code && e.message) {
                setErrorMessage(`Error ${e.code}: ${e.message}`);
            } else {
                setErrorMessage(e.toString());
            }
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (fetchDoorCode === true) getDC();
    }
    , [fetchDoorCode]);

    return { doorCode, error, loading, errorMessage};

}

