import {
	Button,
	Container,
	Stack,
	Table,
	TableCell,
	TableRow,
	Typography,
	Checkbox,
	Snackbar,
	Alert,
	Fade,
} from "@mui/material";
import { useChecklist } from "../useChecklist";
import { useNavigate } from "react-router-dom";

export const LiftLog: React.FC = () => {
	const checklist = useChecklist();
	const navigate = useNavigate();
	return (
		<Fade in={!checklist.loading} timeout={600}>
			<Container>
				<Snackbar
					open={checklist.allChecked}
					autoHideDuration={6000}
					//onClose={handleClose}
					message="Kontrollen er fullført og lagret"
					//action={action}
				>
					<Alert
						//onClose={handleClose}
						severity="success"
						variant="filled"
						sx={{ width: "100%" }}
					>
						Kontroll utført og lagret. God vakt!
					</Alert>
				</Snackbar>

				<Stack>
					<Typography variant="h5">Daglig kontroll</Typography>
					Følgende punkter er kontrollert før oppstart av 1. vakt:
					<form>
						<Table>
							{checklist.items.map((item) => (
								<TableRow>
									<TableCell>
										<Checkbox
											checked={item.checked}
											onChange={(e, c) => {
												item.checked = c;
												checklist.setChecklist([
													...checklist.items,
												]);
											}}
										/>
									</TableCell>
									<TableCell>{item.title}</TableCell>
								</TableRow>
							))}
						</Table>

						<Button
							onClick={() => navigate("/rosterToday")}
							variant="contained"
						>
							Tilbake
						</Button>
					</form>
				</Stack>
			</Container>
		</Fade>
	);
};
